import React from 'react'
import { useEntity } from '@backstage/plugin-catalog-react'
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box
} from '@material-ui/core'
import TechnicalDetailsField from './EntityFields/TechnicalDetails/TechnicalDetailsField'

const EntityTechnicalDetailsCard = () => {
  const { entity } = useEntity()
  const serviceType = entity?.metadata?.annotations?.['sennder.com/service-type']
  const databaseType = entity?.metadata?.annotations?.['sennder.com/database-type']
  const queueType = entity?.metadata?.annotations?.['sennder.com/queue-type']
  const executionMode = entity?.metadata?.annotations?.['sennder.com/execution-mode']

  const dbEngineVersion =
    entity.metadata.annotations?.['sennder.com/database-engine-version']
  const componentRuntime = entity.metadata.annotations?.['sennder.com/runtime']
  const codeCoverage = entity.metadata.annotations?.['sennder.com/code-coverage']
  const componentRuntimeVersion =
    entity.metadata.annotations?.['sennder.com/runtime-version']

  const databaseEngineValue = dbEngineVersion
    ? `${databaseType}:${dbEngineVersion}`
    : databaseType
  const componentRuntimeValue = componentRuntime
    ? `${componentRuntime}:${componentRuntimeVersion}`
    : undefined

  const areAllFieldsUndefined =
    !serviceType &&
    !componentRuntime &&
    !databaseType &&
    !queueType &&
    !executionMode &&
    !codeCoverage

  const databaseTypeSubAnnotations = {
    postgres: [
      'sennder.com/prod-resource-name',
      'sennder.com/datadog-resource-name',
      'sennder.com/replicas'
    ],
    dynamodb: ['sennder.com/prod-resource-name'],
    redis: ['sennder.com/prod-resource-name', 'sennder.com/datadog-resource-name']
  }
  const serviceTypeSubAnnotations = {
    'rest-api': ['sennder.com/metric-type'],
    'outbox-worker': ['sennder.com/topic'],
    consumer: ['sennder.com/topic', 'sennder.com/consumer-group']
  }
  const fields = [
    {
      spec: serviceType,
      specName: serviceType,
      title: 'Service Type',
      subAnnotations: serviceTypeSubAnnotations
    },
    { spec: executionMode, specName: executionMode, title: 'Execution Mode' },
    {
      spec: databaseType,
      specName: databaseEngineValue,
      title: 'Database Engine',
      subAnnotations: databaseTypeSubAnnotations
    },
    { spec: queueType, specName: queueType, title: 'Queue Type' },
    { spec: componentRuntime, specName: componentRuntimeValue, title: 'Runtime Version' },
    { spec: codeCoverage, specName: codeCoverage, title: 'Code Coverage' }
  ]

  return (
    <Card>
      <CardHeader variant="h6" title="Technical Details" />
      <Divider />
      <CardContent>
        {areAllFieldsUndefined ? (
          <Box>
            <Typography variant="body2">
              Technical information not yet provided
            </Typography>
          </Box>
        ) : (
          <Grid container>
            {fields.map(
              (field, index) =>
                field.spec && (
                  <TechnicalDetailsField
                    key={index}
                    spec={field.spec}
                    specName={field.specName}
                    title={field.title}
                    entity={entity}
                    subAnnotations={field.subAnnotations}
                  />
                )
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default EntityTechnicalDetailsCard
