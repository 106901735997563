import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api'

import { rootRouteRef } from './routes'

export const testingMetricsPlugin = createPlugin({
  id: 'testing-metrics',
  routes: {
    root: rootRouteRef
  }
})

export const TestingMetricsPage = testingMetricsPlugin.provide(
  createRoutableExtension({
    name: 'TestingMetricsPage',
    component: () =>
      import('./components/TestingMetricsPage').then(m => m.TestingMetricsPage),
    mountPoint: rootRouteRef
  })
)
