import { Metric, Dimension } from './types'

export type BreakdownLine = {
  group: string
  [value: string]: any
}

export type BreakdownTable = {
  columns: string[]
  lines: BreakdownLine[]
}

async function fetchMetric(options: {
  backendUrl: string
  metric: Metric
  baName?: string
  podName?: string
  token: string
}): Promise<number | undefined> {
  const url = [
    options.backendUrl,
    'api',
    'dora',
    'bas',
    options.baName ?? '*',
    'pods',
    options.podName ?? '*',
    'metrics',
    options.metric
  ].join('/')
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${options.token}`
    }
  })
  if (!response.ok) {
    const text = await response.text()
    throw new Error(
      `Request to Dora backend plugin failed with status ${response.status}: ${text}`
    )
  }

  const result = await response.json()
  return result.value
}

export async function fetchDF(options: {
  backendUrl: string
  token: string
  baName?: string
  podName?: string
}): Promise<number | undefined> {
  return fetchMetric({ ...options, metric: 'df' })
}

export async function fetchLTFC(options: {
  backendUrl: string
  token: string
  baName?: string
  podName?: string
}): Promise<number | undefined> {
  return fetchMetric({ ...options, metric: 'ltfc' })
}

export async function fetchCFR(options: {
  backendUrl: string
  token: string
  baName?: string
  podName?: string
}): Promise<number | undefined> {
  return fetchMetric({ ...options, metric: 'cfr' })
}

export async function fetchMTTR(options: {
  backendUrl: string
  token: string
  baName?: string
  podName?: string
}): Promise<number | undefined> {
  return fetchMetric({ ...options, metric: 'mttr' })
}

export async function fetchBreakdown(options: {
  backendUrl: string
  token: string | undefined
  metric: Metric
  dimension: Dimension
  baName?: string
  podName?: string
}): Promise<BreakdownTable> {
  const url = [
    options.backendUrl,
    'api',
    'dora',
    'bas',
    options.baName ?? '*',
    'pods',
    options.podName ?? '*',
    'metrics',
    options.metric,
    'breakdown',
    options.dimension
  ].join('/')

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${options.token}`
    }
  })

  if (!response.ok) {
    const text = await response.text()
    throw new Error(
      `Request to Dora backend plugin failed with status ${response.status}: ${text}`
    )
  }

  const result = await response.json()
  return result
}
