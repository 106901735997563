/*
 *
 * A fork of:
 *
 * <Backstage>/plugins/catalog-react/src/components/EntityRefLink/FetchedEntityRefLinks.tsx
 * Does the same, but doesn’t swallows the links if the entities referenced
 * were not found.
 *
 */

import {
  Entity,
  CompoundEntityRef,
  parseEntityRef,
  stringifyEntityRef
} from '@backstage/catalog-model'
import React from 'react'
import { EntityRefLink, catalogApiRef } from '@backstage/plugin-catalog-react'
import { ErrorPanel, LinkProps, Progress } from '@backstage/core-components'
import useAsync from 'react-use/lib/useAsync'
import { useApi } from '@backstage/core-plugin-api'

/**
 * Props for {@link FetchedEntityRefLinks}.
 *
 * @public
 */
export type FetchedEntityRefLinksProps<TRef extends string | CompoundEntityRef | Entity> =
  {
    defaultKind?: string
    entityRefs: TRef[]
    getTitle(entity: Entity): string | undefined
  } & Omit<LinkProps, 'to'>

/**
 * Shows a list of clickable links to entities with auto-fetching of entities
 * for customising a displayed text via title attribute.
 *
 * @public
 */
export function FetchedEntityRefLinks<TRef extends string | CompoundEntityRef | Entity>(
  props: FetchedEntityRefLinksProps<TRef>
) {
  const { entityRefs, defaultKind, getTitle, ...linkProps } = props

  const catalogApi = useApi(catalogApiRef)

  const refs = entityRefs.reduce((acc, current) => {
    if (typeof current === 'object' && 'metadata' in current) {
      return acc
    }
    return [...acc, parseEntityRef(current)]
  }, new Array<CompoundEntityRef>())

  const pureEntities = entityRefs.filter(
    ref => typeof ref === 'object' && 'metadata' in ref
  ) as unknown as Array<Entity>

  const {
    value: entities = new Array<Entity>(),
    loading,
    error
  } = useAsync(async () => {
    return refs.length > 0
      ? [
          ...(
            await catalogApi.getEntitiesByRefs({
              entityRefs: refs.map(stringifyEntityRef)
            })
          ).items,
          ...pureEntities
        ]
      : pureEntities
  }, [entityRefs])

  if (loading) {
    return <Progress />
  }

  if (error) {
    return <ErrorPanel error={error} />
  }

  return (
    <>
      {entities.map((r: Entity | undefined, i) => {
        return (
          <React.Fragment key={i}>
            {i > 0 && ', '}
            <EntityRefLink
              {...linkProps}
              defaultKind={defaultKind}
              entityRef={r ?? refs[i]}
              title={r ? getTitle(r as Entity) : stringifyEntityRef(refs[i])}
              style={{ textDecoration: r ? 'none' : 'line-through' }}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
