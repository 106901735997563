import React from 'react'
import { Route } from 'react-router-dom'
import { HomepageCompositionRoot } from '@backstage/plugin-home'
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs'
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin
} from '@backstage/plugin-catalog'
import { CatalogImportPage, catalogImportPlugin } from '@backstage/plugin-catalog-import'
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder'
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react'
import { orgPlugin } from '@backstage/plugin-org'
import { SearchPage } from '@backstage/plugin-search'
import { TechRadarPage } from '@backstage-community/plugin-tech-radar'
import {
  DefaultTechDocsHome,
  techdocsPlugin,
  TechDocsReaderPage,
  TechDocsIndexPage
} from '@backstage/plugin-techdocs'
import { TechDocsAddons } from '@backstage/plugin-techdocs-react'
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib'
import { UserSettingsPage } from '@backstage/plugin-user-settings'
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities'
import { apis } from './apis'
import {
  ComponentNameFieldExtension,
  ComponentTypeFieldExtension,
  SennderGitlabGroupFieldExtension
} from './scaffolder/extensions'
import { HomePage } from './components/home/HomePage'
import { entityPage } from './components/catalog/EntityPage'
import { CustomCatalogIndexPage } from './components/catalog-index/CustomCatalogIndexPage'
import { searchPage } from './components/search/SearchPage'
import { techDocsPage } from './components/techdocs/TechDocsPage'
import { Root } from './components/Root'

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components'
import { createApp } from '@backstage/app-defaults'
import { AppRouter, FlatRoutes } from '@backstage/core-app-api'
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph'
import { RequirePermission } from '@backstage/plugin-permission-react'

import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha'
import { configApiRef, oktaAuthApiRef, useApi } from '@backstage/core-plugin-api'
import { SignInPage } from '@backstage/core-components'
import { badgesPlugin } from '@backstage-community/plugin-badges'

import { FuzzyRedirect } from './components/router/FuzzyRedirect'

// plug-in: OpsGenie
import { OpsgeniePage } from '@k-phoen/backstage-plugin-opsgenie'

// support MermaidJS
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid'

// custom theme
import { ThemeHolder } from './custom/themeApi'
import { FrontendExternalDocsPage } from '@internal/plugin-frontend-external-docs'
import { QaTestResultsPage } from '@internal/plugin-qa-test-results'

import { TechDocsRedirectExtension } from './custom/techdocsRedirect'

import { PlausibleAnalyticsPage } from './components/plausible/PlausibleAnalyticsPage'

// backend auth

import { DoraPage } from '@internal/plugin-dora'
import { techInsightsOverviewPage } from './components/tech-insights/TechInsightsOverviewPage'
import { ContribPage } from '@internal/plugin-contrib'
import { DatadogServiceCatalogPage } from '@internal/plugin-datadog-service-catalog'
import { TestingMetricsPage } from '@internal/plugin-testing-metrics'

const oktaAuthProvider = {
  id: 'okta-auth-provider',
  title: 'Okta',
  message: 'Sign-in using OktaSSO',
  apiRef: oktaAuthApiRef
}

const app = createApp({
  apis,
  plugins: [badgesPlugin],
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        providers={
          useApi(configApiRef).getString('auth.environment') === 'local'
            ? ['guest', oktaAuthProvider]
            : [oktaAuthProvider]
        }
      />
    )
  },
  themes: ThemeHolder('production').getThemes(),
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot
    })
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage
    })
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage
    })
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex
    })
  }
})

const paths = {
  catalogEntity: '/catalog/:namespace/:kind/:name',
  techDocsPage: '/docs/:namespace/:kind/:name/*'
}

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogIndexPage actions={[]} />
    </Route>
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path={paths.catalogEntity} element={<CatalogEntityPage />}>
      <FuzzyRedirect path={paths.catalogEntity}>{entityPage}</FuzzyRedirect>
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    ;
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route path={paths.techDocsPage} element={<TechDocsReaderPage />}>
      <FuzzyRedirect path={paths.techDocsPage}>
        {techDocsPage}
        <TechDocsAddons>
          <ReportIssue />
          <Mermaid
            config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
          />
          <TechDocsRedirectExtension />
        </TechDocsAddons>
      </FuzzyRedirect>
    </Route>
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <SennderGitlabGroupFieldExtension />
        <ComponentNameFieldExtension />
        <ComponentTypeFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/opsgenie" element={<OpsgeniePage />} />
    <Route path="/tech-radar" element={<TechRadarPage width={1500} height={800} />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/frontend-external-docs" element={<FrontendExternalDocsPage />} />
    <Route path="/stats" element={<PlausibleAnalyticsPage />} />
    <Route path="/qa-test-results" element={<QaTestResultsPage />} />
    <Route path="/dora" element={<DoraPage />} />
    <Route path="/tech-insights-overview" element={techInsightsOverviewPage} />
    <Route path="/contrib" element={<ContribPage />} />
    <Route path="/datadog-service-catalog" element={<DatadogServiceCatalogPage />} />
    <Route path="/testing-metrics" element={<TestingMetricsPage />} />
  </FlatRoutes>
)

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>
)
