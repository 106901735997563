/**
 * This component and associated hooks are customized versions of the EntityKindPicker from the Backstage repository.
 *
 * Original Path: `@backstage/plugin-catalog-react/src/components/EntityKindPicker/EntityKindPicker.tsx`
 *
 * Modifications:
 * - Adjusted `EntityKindFilter` import to refer to customized  `CustomEntityKindFilter`.
 * - Set the default value of `initialFilter` to "entity" in `CustomEntityKindPicker` for a consistent initial filter.
 * - Updated `useEntityKindFilter` to include modified filter logic and handle `selectedKind` state updates based on query parameters and filter updates.
 * - Adjusted imports to refer to customized utility functions and components for this custom version.
 *
 * Purpose:
 * - The modifications ensure "entity" acts as a default "All" option, allowing users to reset and view all entities when selected.
 *
 * Maintainer Notes:
 * - These components rely on custom utility functions (`useAllKinds`, `filterKinds`) from `CustomEntityKindUtils`.
 * - Reapply modifications if Backstage releases update the original EntityKindPicker structure.
 */

import { Select } from '@backstage/core-components'
import { alertApiRef, useApi } from '@backstage/core-plugin-api'
import Box from '@material-ui/core/Box'
import React, { useEffect, useMemo, useState } from 'react'
import { EntityKindPickerProps } from '@backstage/plugin-catalog-react'

import { useTranslationRef } from '@backstage/core-plugin-api/alpha'
import {
  catalogReactTranslationRef,
  filterKinds,
  useAllKinds
} from './CustomEntityKindUtils'
import { useEntityList } from '@backstage/plugin-catalog-react'

import { CustomEntityKindFilter } from './CustomEntityKindFilter'

export function useEntityKindFilter(opts: { initialFilter: string }): {
  loading: boolean
  error?: Error
  allKinds: string[]
  selectedKind: string
  setSelectedKind: (kind: string) => void
} {
  const {
    filters,
    queryParameters: { kind: kindParameter },
    updateFilters
  } = useEntityList()

  const queryParamKind = useMemo(() => [kindParameter].flat()[0], [kindParameter])

  const [selectedKind, setSelectedKind] = useState(
    queryParamKind ?? filters.kind?.value ?? opts.initialFilter
  )

  // Set selected kinds on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamKind) {
      setSelectedKind(queryParamKind)
    }
  }, [queryParamKind])

  // Set selected kind from filters; this happens when the kind filter is
  // updated from another component
  useEffect(() => {
    if (filters.kind?.value) {
      setSelectedKind(filters.kind?.value)
    }
  }, [filters.kind])

  useEffect(() => {
    updateFilters({
      kind: selectedKind
        ? new CustomEntityKindFilter(selectedKind, selectedKind)
        : undefined
    })
  }, [selectedKind, updateFilters])

  const { allKinds, loading, error } = useAllKinds()

  return {
    loading,
    error,
    allKinds: allKinds ?? [],
    selectedKind,
    setSelectedKind
  }
}

export const CustomEntityKindPicker = (props: EntityKindPickerProps) => {
  const { allowedKinds, hidden, initialFilter = 'entity' } = props
  const { t } = useTranslationRef(catalogReactTranslationRef)

  const alertApi = useApi(alertApiRef)

  const { error, allKinds, selectedKind, setSelectedKind } = useEntityKindFilter({
    initialFilter: initialFilter
  })

  useEffect(() => {
    if (error) {
      alertApi.post({
        message: t('entityKindPicker.errorMessage'),
        severity: 'error'
      })
    }
  }, [error, alertApi, t])

  if (error) return null

  const options = filterKinds(allKinds, allowedKinds, selectedKind)

  const items = Object.keys(options).map(key => ({
    value: key,
    label: options[key]
  }))

  return hidden ? null : (
    <Box pb={1} pt={1}>
      <Select
        label={t('entityKindPicker.title')}
        items={items}
        selected={selectedKind.toLocaleLowerCase('en-US')}
        onChange={value => setSelectedKind(String(value))}
      />
    </Box>
  )
}
